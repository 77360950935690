const DEFAULT_ERROR_OPTIONS = {
  "closeButton": true,
  "preventDuplicates": true,
  "positionClass": "toast-top-right",
  "tapToDismiss": false,
  "showDuration": 0,
  "hideDuration": 0,
  "timeOut": 0,
  "extendedTimeOut": 0,
  "showEasing": "swing",
  "hideEasing": "linear",
  "showMethod": "fadeIn",
  "hideMethod": "fadeOut"
}

export default class CustomToastr {
  // Class is made to be extendable in a few different ways. We can add classes or add method to support calling new toasts
  // Basic toastr call format is toastr.XXXX(message, title, options)
  // Where XXX is error/success/info and the other toastr types
  // message can be a string
  // title can be a string
  // options unless changed are set to default options for error toasts, which should never close unless the user chooses to close it or navigate away

  static info(message, title='', options = DEFAULT_ERROR_OPTIONS){
    return toastr.info(message, title, options)
  }

  static success(message, title='', options = DEFAULT_ERROR_OPTIONS){
    return toastr.success(message, title, options)
  }

  static error(message, title='', options = DEFAULT_ERROR_OPTIONS){
    return toastr.error(message, title, options)
  }

  static warning(message, title='', options = DEFAULT_ERROR_OPTIONS){
    return toastr.warning(message, title, options)
  }

  static clear(element) {
    toastr.clear($(element).closest('.toast'))
  }

  static remove(element) {
    const toast = $(element).closest('.toast')
    toast.hide()
    toastr.remove(toast)
  }
}
