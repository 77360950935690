import $ from 'jquery'
import CustomToastr from '@utils/custom_toastr.js'
import Rails from '@rails/ujs'

$.ajax({
  dataType: 'json',
  url:      '/parameter.json',
  async:    false,
  success(data) {
    window.ConfigManager = data
  }
})

if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
  window.info = (message) => { console.log(`[${new Date().getTime()}]INFO: ${message}`) }
} else {
  window.info = (_message) => {}
}

window.raiseException = function (message) {
  const error = new Error(this, this.constructor)

  error.name = this.constructor.name
  error.message = message

  console.log(`ERROR: ${message}`)

  if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test') {
    throw error
  }
}

window.customToastr = CustomToastr
window.Rails = Rails

